import GuidUtils from '@/shared/utils/guid'

export default class StudentRegisteredSearch {
  constructor({
    id,
    age,
    birthDate,
    code,
    contactName,
    contactPhone,
    cpf,
    dateLastUpdate,
    familyBag,
    familyIncome,
    fatherName,
    gender,
    healthPlanName,
    localTrainingId,
    localTrainingName,
    modalityId,
    modalityName,
    motherName,
    name,
    rg,
    schoolName,
    socialEntityName,
    status,
    yearRegistration
    
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.age = age,
    this.birthDate = birthDate,
    this.code = code,
    this.contactName = contactName,
    this.contactPhone = contactPhone,
    this.cpf = cpf,
    this.dateLastUpdate = dateLastUpdate,
    this.familyBag = familyBag,
    this.familyIncome = familyIncome
    this.fatherName = fatherName
    this.gender = gender
    this.healthPlanName = healthPlanName
    this.localTrainingId = localTrainingId
    this.localTrainingName = localTrainingName
    this.modalityId = modalityId
    this.modalityName = modalityName
    this.motherName = motherName
    this.name = name
    this.rg = rg
    this.schoolName = schoolName
    this.socialEntityName = socialEntityName
    this.status = status
    this.yearRegistration = yearRegistration
  }
}