import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'classlesson'

const GetAll = (classId, pagination, criteria) => SocialProjectManager.get(`${resourceName}/frequency/${classId}`,
  { params: merge(pagination, criteria) })

const CancelClassLesson = classLessonId => SocialProjectManager.delete(`${resourceName}/${classLessonId}`)

const ClassLessonRepository = {
  GetAll,
  CancelClassLesson
}

export default ClassLessonRepository
