export default class ClassLessonFrequencyCriteria {
  constructor({
    dateStart,
    dateEnd,
    situation,
    frequencyStatus
  } = {}) {
    this.dateStart = dateStart
    this.dateEnd = dateEnd
    this.situation = situation
    this.frequencyStatus = frequencyStatus
  }
}
