const AbsenceJustification = {
  NONE:   { id: 0, name: 'Sem Justificativa' },
  SCHOOL: { id: 1, name: 'Escola' },
  HEALTH: { id: 2, name: 'Saúde' },
  TRAVEL: { id: 3, name: 'Viagem' },
  OTHERS: { id: 4, name: 'Outras' }
}

Object.freeze(AbsenceJustification)

export default AbsenceJustification
