import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import ClassTeacher from '@/shared/models/classTeacher'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import { dateFilter } from '@/shared/filters/ToDate'
import { TheMask } from 'vue-the-mask'
import Roles from '@/shared/mixins/hasRoles'

export default {
  name: 'TableTeacher',

  mixins: [Roles],

  props: {
    classTeacher: Array
  },
  componentes: {
    TheMask
  },

  data: () => ({
    teacher: new ClassTeacher(),
    showModalClassTeacher: false,
    showModalDeleteTeacher: false,
    emptyTextClassTeacher: 'Nenhum professor associado à turma',
    optionsTeachers: [],
    teacherIndexSelected: [],
    dateStartFormatted: null,
    dateEndFormatted: null,
    timeBegin: '',
    timeEnd: '',
    fieldClassTeacher: [
      {
        key: 'teacherId',
        label: 'NOME DO PROFESSOR',
        class: 'left-header-border text-center',
        formatter: 'getTeacherName'
      },
      {
        key: 'dateStart',
        label: 'DATA DE INÍCIO',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'dateEnd',
        label: 'DATA FINAL',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    rows: 100,
    perPage: 10,
    currentPage: 1
  }),

  created() {
    this.clearForm()
    this.$validator.reset()
    this.getAllTeacher()
  },

  methods: {
    getAllTeacher() {
      loading.push()
      TeacherRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsTeachers = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professores', 'ERRO')
        })
    },

    onSave() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.mergeItemList()
          this.clearForm()
          this.showModalClassTeacher = false
        } else {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
        }
      })
    },

    onAddTeacher() {
      this.clearForm()
      this.teacherIndexSelected = null
      this.showModalClassTeacher = true
    },

    onEditTeacher(teacher) {
      this.teacherIndexSelected = teacher.index
      this.teacher = new ClassTeacher(teacher.item)
      this.showModalClassTeacher = true
    },

    clearForm() {
      this.dateStartFormatted = null,
      this.dateEndFormatted = null,
      this.teacher = new ClassTeacher()
    },

    selectAllRows() {
      this.$refs.getStudents.selectAllRows()
    },

    onShowModalDeleteTeacher(index) {
      this.teacherIndexSelected = index
      this.showModalDeleteTeacher = true
    },

    getTeacherName(teacherId) {
      const teacherName = this.optionsTeachers.find(teacher => teacher.id == teacherId)

      if (!teacherName) return

      return teacherName.name
    },

    mergeItemList() {
      const isEditing = this.teacherIndexSelected != null

      if (isEditing) {
        this.classTeacher.splice(this.teacherIndexSelected, 1, this.teacher)
        return
      }

      this.classTeacher.push(this.teacher)
    },

    onInactive() {
      this.removeTeacher()
      this.showModalDeleteTeacher = false
      this.teacherIndexSelected = null
    },

    removeTeacher() {
      const index = this.teacherIndexSelected
      const hasElement = this.classTeacher[index] != null

      if (!hasElement) {
        toast.error('Não foi possível excluir o professor', 'ERRO')
        return
      }

      this.classTeacher.splice(index, 1)
      this.showModalDeleteTeacher = false
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },

    formatDate(event) {
      this.teacher.dateStart = dateFilter(event.currentTarget.value)
    },

    formatDateEnd(event) {
      this.teacher.dateEnd = dateFilter(event.currentTarget.value)
    },

    onContext(ctx) {
      this.dateStartFormatted = ctx.selectedFormatted
    },

    onContextEnd(ctx) {
      this.dateEndFormatted = ctx.selectedFormatted
    }
  }
}
