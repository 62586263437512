import GuidUtils from '@/shared/utils/guid'

export default class classSchedule {
  constructor({
    classId,
		days,
		dayWeek,
		dayWeekName,
		id,
		timeEnd,
		timeStart

  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.days = days,
    this.classId = classId,
    this.dayWeek = dayWeek,
    this.dayWeekName = dayWeekName,
    this.timeEnd = timeEnd,
    this.timeStart = timeStart
  }
}