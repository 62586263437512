import ClassLessonRepository from '@/shared/http/repositories/socialProject/classlesson'
import ClassLessonStudentRepository from '@/shared/http/repositories/socialProject/classlessonstudent'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import ClassFrequency from '@/shared/models/classFrequency'
import ClassStudent from '@/shared/models/classStudent'
import Criteria from '@/shared/models/criteria/classLessonFrequencyCriteria'
import FrequencyStatus from '@/shared/enums/classLessonFrequencyStatus'
import AbsenceJustification from '@/shared/enums/absenceJustification'
import YesNo from '@/shared/enums/yesNo'
import Roles from '@/shared/mixins/hasRoles'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import { customDateFilter } from '@/shared/filters/ToCustomDate'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import moment from 'moment'

import { globalGetters as ClassGetters } from '@/store/modules/class/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'Frequency',

  components: {
    [Pagination.name]: Pagination
  },

  mixins: [Roles],

  data: () => ({
    classFrequency: new ClassFrequency(),
    student: new ClassStudent(),
    classLessons: [],
    frequencies: [],
    criteria: new Criteria(),
    classLessonId: null,
    sum: {
      presents: null,
      notPresents: null
    },
    eFrequencyStatus: Object.values(FrequencyStatus),
    eAbsenceJustification: Object.values(AbsenceJustification),
    eYesNo: Object.values(YesNo),
    showModal: false,
    showDrawer: false,
    emptyText: 'Não há chamadas para exibir',
    dateStartFormatted: null,
    dateEndFormatted: null,
    fields: [
      {
        key: 'date',
        label: 'DATA',
        class: 'left-header-border text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'totalPresents',
        label: 'PRESENTES',
        class: 'middle-header text-center'
      },
      {
        key: 'totalNotPresents',
        label: 'AUSENTES',
        class: 'middle-header text-center'
      },
      {
        key: 'frequencyStatusName',
        label: 'CHAMADA',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'ACÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 15,
      index: 1
    }
  }),

  computed: {
    ...mapGetters({
      classe: ClassGetters.classe
    })
  },

  methods: {
    absenceValue(item) {
      if (item.isPresent) return

      if (!item.isPresent && item.absenceJustification !== null) return

      if (!item.isPresent && item.absenceJustification === null) {
        item.absenceJustification = 0
        return
      }
    },

    getFrequencies() {
      if (!this.criteria.dateStart) {
        return toast.warning('Uma data inicial é necessária', 'AVISO')
      }

      if (this.criteria.frequencyStatus != undefined && !this.criteria.dateEnd) {
        return toast.warning('É necessário informar Data Inicial e Final para filtrar por Chamada', 'AVISO')
      }

      if(this.criteria.dateStart && this.criteria.dateEnd) {
        const start = moment(this.criteria.dateStart)
        const end = moment(this.criteria.dateEnd)
        if (start.isAfter(end)) {
          return toast.warning('Data Inicial deve ser menor ou igual a Data Final', 'AVISO')
        }
      }

      loading.push()
      ClassLessonRepository.GetAll(this.classe.id, this.pagination, this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.classLessons = res.data.data.map(frequency => new ClassFrequency(frequency))
          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          this.sum = {
            presents: 0,
            notPresents: 0
          }
          this.classLessons.reduce((acc, { totalPresents, totalNotPresents }) => {
            acc.presents += totalPresents
            acc.notPresents += totalNotPresents
            return acc
          }, this.sum)

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pesquisar frequências', 'ERRO')
        })
    },

    selectRow(classFrequency) {
      this.classFrequency = new ClassFrequency()
      this.classFrequency = new ClassFrequency(classFrequency)
      this.showModal = true
    },

    onCancelClassLesson() {
      loading.push()
      ClassLessonRepository.CancelClassLesson(this.classFrequency.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          this.showModal = false
          this.getFrequencies()
          toast.success('Aula cancelada com sucesso', 'CANCELAR AULA')
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao cancelar aula', 'ERRO')
        })
    },

    getClassLessonStudents(classLesson) {
      this.classLessonId = classLesson.id

      loading.push()
      ClassLessonStudentRepository.GetStudentsClassLesson(this.classLessonId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.frequencies = res.data.data.map(student => new ClassStudent(student))

          if(classLesson.frequencyStatus === 0) {
            this.frequencies.forEach(x => { x.isPresent = true; x.absenceJustification = null })
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar alunos da turma', 'ERRO')
        })
    },

    SaveFrequency() {
      loading.push()
      ClassLessonStudentRepository.SaveFrequency(this.frequencies, this.classLessonId)
        .then(res => {
          if (!IsSuccessCode(res)) Promise.reject()

          this.frequencies = []
          this.onCloseDrawer()
          toast.success('Chamada realizada com sucesso', 'SALVAR CHAMADA')
          this.getFrequencies()
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar presenças', 'ERRO')
        })
    },

    onShowDrawer(id) {
      this.getClassLessonStudents(id)
      this.showDrawer = true
    },

    onCloseDrawer() {
      this.showDrawer = false
    },

    onContextDateStart(ctx) {
      this.dateStartFormatted = ctx.selectedFormatted
    },

    onContextDateEnd(ctx) {
      this.dateEndFormatted = ctx.selectedFormatted
    },

    formatDateStart(event) {
      this.criteria.dateStart = customDateFilter(event.currentTarget.value)
    },

    formatDateEnd(event) {
      this.criteria.dateEnd = customDateFilter(event.currentTarget.value)
    },

    lastSevenDays() {
      let sevenDaysAgo = moment().subtract(7, 'days')

      sevenDaysAgo = sevenDaysAgo.toISOString()
      this.criteria.dateStart = sevenDaysAgo
      this.getFrequencies()
    }
  }
}
