export default class ClassStudent {
  constructor({
    classLessonStudentId,
    classLessonId,
    studentId,
    name,
    isPresent,
    absenceJustification
  } = {}) {
    this.classLessonStudentId = classLessonStudentId
    this.classLessonId = classLessonId
    this.studentId = studentId
    this.name = name
    this.isPresent = isPresent
    this.absenceJustification = absenceJustification
  }
}
