import Vue from 'vue'
import moment from 'moment'

// eslint-disable-next-line
export const customDateFilter = (value) => {
  if (!value) return undefined

  moment.locale('pt-br')

  const d = moment(value, ["DD/MM/YYYY", moment.ISO_8601])

  if (d.isValid()) {
    return d.format('YYYY-MM-DD')
  }

  return undefined
}

Vue.filter('ToCustomDate', customDateFilter)
