import CategoryRepository from '@/shared/http/repositories/socialProject/category'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import TableRegisteredStudentsNoSelectable from '@/components/layout/TableRegisteredStudentsNoSelectable/tableRegisteredStudentsNoSelectable.vue'
import TableRegisteredStudents from '@/components/layout/TableRegisteredStudents/tableRegisteredStudents.vue'
import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalitylocaltraining'
import TableSearchStudent from '@/components/layout/TableSearchStudent/tableSearchStudent.vue'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Search from '@/components/layout/Search/search.vue'
import StudentEvolution from './components/StudentEvolution/studentEvolution.vue'
import StudentMoviment from './components/StudentMoviment/studentMoviment.vue'
import TableTeacher from './components/TableTeacher/tableTeacher.vue'
import TableHours from './components/TableHours/tableHours.vue'
import Frequency from './components/Frequency/Frequency.vue'
import ClassList from './components/ClassList/ClassList.vue'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import GuidUtils from '@/shared/utils/guid'
import Classe from '@/shared/models/class'
import Roles from '@/shared/mixins/hasRoles'

import { globalActions as ClassActions } from '@/store/modules/class/actionTypes'
import { globalGetters as ClassGetters } from '@/store/modules/class/getters'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [Roles],

  components: {
    [TableRegisteredStudentsNoSelectable.name]: TableRegisteredStudentsNoSelectable,
    [TableRegisteredStudents.name]: TableRegisteredStudents,
    [TableSearchStudent.name]: TableSearchStudent,
    [StudentEvolution.name]: StudentEvolution,
    [StudentMoviment.name]: StudentMoviment,
    [TableTeacher.name]: TableTeacher,
    [BreadCrumb.name]: BreadCrumb,
    [TableHours.name]: TableHours,
    [Search.name]: Search,
    [Frequency.name]: Frequency,
    [ClassList.name]: ClassList
  },

  data: () => ({
    labelSearch: 'Buscar Alunos',
    movimentLocalTrainingId: null,
    movimentModalityId: null,
    movimentCategoryId: null,
    tabName: null,
    classe: new Classe(),
    enable: true,
    movRegistrationsStudents: [],
    evoRegistrationStudents: [],
    modalityLocalTraining: [],
    evoRegisteredStudents: [],
    movRegisteredStudents: [],
    listLocalTraining: [],
    categories: [],
    modalities: [],
    rows: 100,
    perPage: 10,
    currentPage: 1,
    tabIndex: 0,
    isDisabled: true,
    isNew: true
  }),

  created() {
    this.loadModalityLocalTraining()
  },

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Turmas',
            active: true
          }
        ]
      }
    },

    ...mapGetters({
      classGetter: ClassGetters.classe
    })
  },

  methods: {
    ...mapActions({
      setClass: ClassActions.SET_CLASS
    }),

    addClass(newTabIndex, isDisabled) {
      this.clearForm()
      this.$validator.reset()

      this.isDisabled = isDisabled
      this.isNew = true

      this.$nextTick(() => {
        this.tabIndex = newTabIndex
      })
    },

    editClass(newTabIndex, isDisabled, classId) {
      this.clearForm()
      this.$validator.reset()

      this.isDisabled = isDisabled
      this.isNew = false
      this.getById(classId)

      this.$nextTick(() => {
        this.tabIndex = newTabIndex
      })
    },

    getById(classId) {
      loading.push()
      ClassRepository.GetById(classId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.classe = new Classe(res.data.data)
          this.onSelectModality()
          this.onSelectLocalTraining()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar esta turma', 'ERRO')
        })
    },

    loadModalityLocalTraining() {
      this.modalityLocalTraining = []
      loading.push()
      ModalityLocalTrainingRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.modalityLocalTraining = res.data.data
          this.onSelectModality()
          this.onSelectLocalTraining()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar a lista de Modalidades e Locais de treinamento.', 'ERRO')
        })
    },

    onSave() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
          return
        }

        const isNew = GuidUtils.isEmpty(this.classe.id)

        if (isNew) {
          this.onSaveCreate()
          return
        }

        this.onSaveEdit()
      })
    },

    onSaveCreate() {
      loading.push()
      ClassRepository.Create(this.classe)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.success('Registro salvo com sucesso', 'SUCESSO')
          this.isNew = false
          window.scrollTo(0, 0)
          this.setClass(res.data.data)
          loading.pop()
        })
        .catch(() => {
          toast.error('Não foi possível salvar registro.', 'ERRO')
          loading.pop()
        })
    },

    onSaveEdit() {
      loading.push()
      ClassRepository.Update(this.classe)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.setClass(res.data.data)
          window.scrollTo(0, 0)
          toast.success('Registro atualizado com sucesso', 'SUCESSO')
          loading.pop()
        })
        .catch(() => {
          toast.error('Não foi possível atualizar registro.', 'ERRO')
          loading.pop()
        })
    },

    onSelectLocalTraining() {
      let list = this.modalityLocalTraining

      if (this.classe.localTrainingId) {
        const type = this.listLocalTraining.filter(s => s.id === this.classe.localTrainingId)

        if (type.length > 0) {
          this.classe.localTrainingName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.localTrainingId === type[0].id)
        }
      }

      this.modalities = []
      for (var i = 0; i < list.length; i++) {
        let modality = {
          id: list[i].modalityId,
          name: list[i].modalityName
        }

        if (this.modalities.filter(m => m.id === modality.id).length === 0) {
          this.modalities.push(modality)
        }
      }
    },

    onSelectModality() {
      let list = this.modalityLocalTraining

      if (this.classe.modalityId) {
        let type = this.modalities.filter(s => s.id === this.classe.modalityId)

        if (type.length > 0) {
          this.classe.modalityName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.modalityId === type[0].id)
        }
      }

      this.listLocalTraining = []
      for (var i = 0; i < list.length; i++) {
        const local = {
          id: list[i].localTrainingId,
          name: list[i].localTrainingName
        }

        if (this.listLocalTraining.filter(l => l.id === local.id).length === 0) {
          this.listLocalTraining.push(local)
        }
      }

      this.categories = []
      if (this.classe.modalityId) {
        CategoryRepository.GetAllByModality(this.classe.modalityId)
          .then(res => {
            this.categories = res.data.data
          })
          .catch(() => {
            toast.error('Não foi possível carregar a lista de Categorias.', 'ERRO')
          })
      }
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },

    toEvolutionRegistration(evoRegistrations) { this.evoRegistrationStudents = evoRegistrations },

    updateRegistration() {
      this.$refs.registeredStudentNoSelectable.LoadClassRegistration()
      this.$refs.tabMovTableRegisteredStudent.LoadClassRegistration()
      this.$refs.tabEvoTableRegisteredStudents.LoadClassRegistration()
    },

    toMovementRegistrations(registrations) { this.movRegistrationsStudents = registrations },

    toEvolutionStudents(evoStudents) { this.evoRegisteredStudents = evoStudents },

    onSearchedStudent() { this.$refs.searchStudent.onSaveSearchedStudent() },

    toMovementStudents(students) { this.movRegisteredStudents = students },

    enableEnrollButton(enabledButton) { this.enable = enabledButton },

    onRowSelected(items) { this.selected = items },

    clearForm() { this.classe = new Classe() },

    setTabName(name) { this.tabName = name },

    backTo() {
      this.$nextTick(() => {
        this.tabIndex = 0
      })
    }
  }
}
