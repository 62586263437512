import ClassRegistrationRepository from '@/shared/http/repositories/socialProject/classregistration'
import StudentRegistered from '@/shared/models/studentRegistered'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import Roles from '@/shared/mixins/hasRoles'

export default {
  name: 'TableRegisteredStudents',

  mixins: [Roles],

  props: {
    classeId: {
      type: String
    },
    tabName: {
      type: String
    }
  },

  data: () => ({
    emptyTextRegistered: 'Nenhum aluno matrículado',
    showModalDeleteRegisteredStudents: false,
    registrations: [],
    selected: [],
    name: null,
    totalRegister: 0,
    fieldRegisteredStudents: [
      {
        key: 'selected',
        label: 'SELEÇÃO',
        class: 'left-header-border text-center'
      },
      {
        key: 'studentCode',
        label: 'CÓDIGO',
        class: 'middle-header text-center'
      },
      {
        key: 'studentName',
        label: 'NOME',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'studentGender',
        label: 'SEXO',
        class: 'middle-header text-center'
      },
      {
        key: 'studentAge',
        label: 'IDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'dateStart',
        label: 'DATA DE INÍCIO',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'birthDate',
        label: 'DATA DE NASCIMENTO',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    rows: 100,
    perPage: 10,
    currentPage: 1
  }),

  watch: {
    classeId: function (value) {
      this.LoadClassRegistration(value)
    }
  },

  methods: {
    LoadClassRegistration() {
      loading.push()
      ClassRegistrationRepository.GetByClass(this.classeId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.registrations = res.data.data.map(studentRegistered => {
            return new StudentRegistered(studentRegistered)
          })

          if (this.registrations.length >= 1) {
            this.totalRegister = this.registrations.length
          }

          this.$emit('registrationStudent', this.registrations)

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar os alunos registrados', 'ERRO')
        })
    },

    selectRowDelete(id, name) {
      this.id = id
      this.name = name
      this.showModalDeleteRegisteredStudents = true
    },

    onDelete() {
      loading.push()
      ClassRegistrationRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.success('Matrícula excluída com sucesso!', 'EXCLUSÃO')
          this.LoadClassRegistration()
          this.$emit('deleteReloadRegistration')
          this.showModalDeleteRegisteredStudents = false

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir este aluno matriculado', 'ERRO')
        })
    },

    onRowSelected(items) {
      this.selected = items

      if (this.tabName === 'movimentacao') {
        this.$emit('movRegisteredStudent', this.selected)
      }
      if (this.tabName === 'evolucao') {
        this.$emit('evoRegisteredStudents', this.selected)
      }
      return
    },

    selectAllRows() { this.$refs.registeredStudents.selectAllRows() },

    clearSelected() { this.$refs.registeredStudents.clearSelected() }
  }
}
