import GuidUtils from '@/shared/utils/guid'

export default class ClassTeacher {
  constructor({
    classId,
		dateEnd,
		dateStart,
		id,
		teacherId,
		teacherName

  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.dateEnd = dateEnd,
    this.classId = classId,
    this.dateStart = dateStart,
    this.teacherId = teacherId,
    this.teacherName = teacherName
  }
}