import FrequencyStatus from '@/shared/enums/classLessonFrequencyStatus'

export default class ClassFrequency {
  constructor({
    id,
    date,
    totalPresents,
    totalNotPresents,
    frequencyStatus
  } = {}) {
    this.id = id
    this.date = date
    this.totalPresents = totalPresents
    this.totalNotPresents = totalNotPresents
    this.frequencyStatus = frequencyStatus
    this.frequencyStatusName = this.getFrequencyStatus(frequencyStatus)
  }

  getFrequencyStatus(frequencyStatus) {
    const eFrequencyStatus = Object.values(FrequencyStatus)
    const frequencyStatusObj = eFrequencyStatus.find(x => x.id === frequencyStatus)

    if(!frequencyStatusObj) return ''

    return frequencyStatusObj.name
  }
}
