import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalitylocaltraining'
import CategoryRepository from '@/shared/http/repositories/socialProject/category'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import Search from '@/components/layout/Search/search.vue'
import Roles from '@/shared/mixins/hasRoles'
import ClassCriteria from '@/shared/models/criteria/classcriteria'
import Classe from '@/shared/models/class'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'

import { globalActions as ClassActions } from '@/store/modules/class/actionTypes'
import { globalGetters as ClassGetters } from '@/store/modules/class/getters'
import { globalActions as TeacherActions } from '@/store/modules/teacher/actionTypes'
import { globalGetters as TeacherGetters } from '@/store/modules/teacher/getters'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ClassList',

  components: {
    [Pagination.name]: Pagination,
    [Search.name]: Search
  },

  mixins: [Roles],

  data: () => ({
    id: null,
    criteria: new ClassCriteria(),
    listLocalTraining: [],
    optionsTeachers: [],
    modalityLocalTraining: [],
    modalities: [],
    categories: [],
    emptyClass: 'Não há registros',
    showModalDeleteClass: false,
    labelSearch: 'Listagem de Turmas',
    fieldClass: [
      {
        key: 'localTrainingName',
        label: 'LOCAL DE TREINAMENTO',
        class: 'left-header-border text-center',
        sortable: true,
      },
      {
        key: 'modalityCategoryName',
        label: 'MODALIDADE / CATEGORIA',
        class: 'middle-header text-center'
      },
      {
        key: 'name',
        label: 'NOME DA TURMA',
        class: 'middle-header text-center'
      },
      {
        key: 'days',
        label: 'DIAS DA SEMANA',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 0,
      limit: 10,
      index: 1
    }
  }),

  computed: {
    ...mapGetters({
      classes: ClassGetters.classes,
      classe: ClassGetters.classe,
      teachers: TeacherGetters.teachers,
      paginationGetter: ClassGetters.pagination
    })
  },

  created() {
    this.loadModalityLocalTraining()
    this.fetchTeachers()
  },

  methods: {
    ...mapActions({
      fetchClasses: ClassActions.FETCH_CLASSES,
      setClass: ClassActions.SET_CLASS,
      fetchTeachers: TeacherActions.FETCH_TEACHERS
    }),

    addClass() {
      this.setClass(new Classe())
      this.$emit('addClass', 1, false)
    },

    editClass(classe) {
      this.setClass(new Classe())
      this.setClass(classe)
      this.$emit('editClass', 1, false, classe.id)
    },

    async getAll() {
      await this.fetchClasses({ criteria: this.criteria, pagination: this.pagination })

      this.pagination = {
        count: this.paginationGetter.count,
        limit: this.paginationGetter.limit,
        index: this.paginationGetter.index
      }
    },

    selectRow(id) {
      this.id = null
      this.showModalDeleteClass = true
      this.id = id
    },

    onDelete() {
      loading.push()
      ClassRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAll()
          this.showModalDeleteClass = false
          toast.success('Turma excluída com sucesso!', 'EXCLUSÃO DE TURMAS')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir uma turma', 'ERRO')
        })
    },

    loadModalityLocalTraining() {
      this.modalityLocalTraining = []
      loading.push()
      ModalityLocalTrainingRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.modalityLocalTraining = res.data.data
          this.onSelectModality()
          this.onSelectLocalTraining()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar a lista de Modalidades e Locais de treinamento.', 'ERRO')
        })
    },

    onSelectLocalTraining() {
      let list = this.modalityLocalTraining

      if (this.criteria.localTrainingId) {
        const type = this.listLocalTraining.filter(s => s.id === this.criteria.localTrainingId)

        if (type.length > 0) {
          this.criteria.localTrainingName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.localTrainingId === type[0].id)
        }
      }

      this.modalities = []
      for (var i = 0; i < list.length; i++) {
        let modality = {
          id: list[i].modalityId,
          name: list[i].modalityName
        }

        if (this.modalities.filter(m => m.id === modality.id).length === 0) {
          this.modalities.push(modality)
        }
      }
    },

    onSelectModality() {
      let list = this.modalityLocalTraining

      if (this.criteria.modalityId) {
        let type = this.modalities.filter(s => s.id === this.criteria.modalityId)

        if (type.length > 0) {
          this.criteria.modalityName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.modalityId === type[0].id)
        }
      }

      this.listLocalTraining = []
      for (var i = 0; i < list.length; i++) {
        const local = {
          id: list[i].localTrainingId,
          name: list[i].localTrainingName
        }

        if (this.listLocalTraining.filter(l => l.id === local.id).length === 0) {
          this.listLocalTraining.push(local)
        }
      }

      this.categories = []
      if (this.criteria.modalityId) {
        CategoryRepository.GetAllByModality(this.criteria.modalityId)
          .then(res => {
            this.categories = res.data.data
          })
          .catch(() => {
            this.$refs.snackBar.ShowError('Não foi possível carregar a lista de Categorias.')
          })
      }
    }
  }
}
