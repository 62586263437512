import ClassRegistrationRepository from '@/shared/http/repositories/socialProject/classregistration'
import CategoryRepository from '@/shared/http/repositories/socialProject/category'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import Roles from '@/shared/mixins/hasRoles'

export default {
  name: 'StudentEvolution',

  mixins: [Roles],

  props: {
    categoryId: {
      type: String
    },
    evoRegisteredStudents: {
      type: Array
    },
    evoRegistrationStudents: {
      type: Array
    }
  },

  data: () => ({
    newClassId: null,
    classesEvolution: [],
    category: []
  }),

  computed: {
    enableEvolutionButton() {
      if (this.newClassId) return false

      return true
    }
  },

  methods: {
    LoadNextCategory(categoryId) {
      this.category = []
      CategoryRepository.GetNextCategory(categoryId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          this.category = res.data.data
          if (this.category) {
            this.LoadClassEvolution(this.category.id)
          }
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar a lista de Categorias.', 'ERRO')
        })
    },

    onSave() {
      ClassRegistrationRepository.SaveMovement(this.newClassId, this.evoRegisteredStudents)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if (this.evoRegisteredStudents) {
            this.evoRegisteredStudents.forEach(element => {
              var index = this.evoRegistrationStudents.findIndex(item => item.id === element.id)
              this.evoRegistrationStudents.splice(index, 1)
            })
          }
          this.clearForm()
          this.$emit('loadClassRegistration')

          window.scrollTo(0, 0)
          loading.pop()
          toast.success('Evolução realizada com sucesso!', 'SUCESSO')
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível realizar a evolução.', 'ERRO')
        })
    },

    LoadClassEvolution(categoryId) {
      this.classesEvolution = []
      ClassRepository.GetAllByCategory(categoryId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.classesEvolution = res.data.data
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar as turmas para evolução.', 'ERRO')
        })
    },

    clearForm() { this.newClassId = null }
  }
}
