import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'classlessonstudent'

const GetFrequency = criteria => SocialProjectManager.get(`${resourceName}/frequency`, { params: criteria })
const GetTotalFrequency = criteria => SocialProjectManager.get(`${resourceName}/total-frequency`, { params: criteria })
const GetStudentsClassLesson = classLessonId => SocialProjectManager.get(`${resourceName}/${classLessonId}/students`)

const SaveFrequency = (frequencies, classLessonId) => SocialProjectManager.post(`${resourceName}/${classLessonId}`, frequencies)

const ClassLessonStudentRepository = {
  GetFrequency,
  GetTotalFrequency,
  GetStudentsClassLesson,
  SaveFrequency
}

export default ClassLessonStudentRepository
