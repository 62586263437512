import ClassRegistrationRepository from '@/shared/http/repositories/socialProject/classregistration'
import StudentRepository from '@/shared/http/repositories/socialProject/student'
import Search from '@/components/layout/Search/search.vue'
import StudentRegistered from '@/shared/models/studentRegisteredSearch'
import GuidUtils from '@/shared/utils/guid'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'

export default {
  name: 'TableSearchStudent',
  props: {
    classeId: {
      type: String,
    },
    enable: {
      type: Boolean
    }
  },
  components: {
    [Search.name]: Search,
  },

  data: () => ({
    classId:  null,
    searchedStudents: [],
    searchText: null,
    selected: null,
    enabledButton: null,
    emptyTextGetStudents: 'Faça uma pesquisa para o preenchimento desta tabela',
    fieldGetStudents: [
      {
        key: 'selected',
        label: 'SELEÇÃO',
        class: 'left-header-border text-center'
      },
      {
        key: 'code',
        label: 'CÓDIGO',
        class: 'middle-header text-center'
      },
      {
        key: 'name',
        label: 'NOME',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'gender',
        label: 'SEXO',
        class: 'middle-header text-center'
      },
      {
        key: 'age',
        label: 'IDADE',
        class: 'middle-header text-center',
      },
      {
        key: 'schoolName',
        label: 'ESCOLA',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 10,
      index: 1
    }
  }),

  methods: {
    getAll() {
      if(!this.searchText) return

      loading.push()
      StudentRepository.GetNotInClass(this.searchText, this.classeId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.searchedStudents = res.data.data.map(student => {
            return new StudentRegistered(student)
          })

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar alunos', 'ERRO')
        })
    },

    onRowSelected(items) {
      this.selected = items
      this.enabledButton = this.enable
      this.selected.length >= 1 ? this.enabledButton = false : this.enabledButton = true
      this.$emit('enable-button', this.enabledButton)
    },

    onSaveSearchedStudent () {
      if (!this.$route.params.id || GuidUtils.isEmpty(this.$route.params.id)) {
        this.classId = this.classeId
      } else {
        this.classId = this.$route.params.id
      }

      ClassRegistrationRepository.Create(this.classId, this.selected)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.registrations = res.data.data
          this.$emit('updateRegisteredStudents')

          if (this.selected) {
            this.selected.forEach(element => {
              var index = this.searchedStudents.findIndex(item => item.id === element.id)
              this.searchedStudents.splice(index, 1)
            })

            window.scrollTo(0, 0)
            this.selected = []
          }
          toast.success('Matrícula efetuada com sucesso!')
          return Promise.resolve()
        })
        .catch(() => {
          toast.error('Não foi possível realizar as matrículas')
        })
    },

    search(searchText) {
      this.searchText = searchText
      this.getAll()
    },

    selectAllRows() {
      this.$refs.getStudents.selectAllRows()
    },

    clearSelected() {
      this.$refs.getStudents.clearSelected()
    }
  }
}
