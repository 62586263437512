import ClassScheduleRepository from '@/shared/http/repositories/socialProject/class-schedule'
import ClassSchedule from '@/shared/models/classSchedule'
import DaysWeek from '@/shared/enums/daysWeek'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import Roles from '@/shared/mixins/hasRoles'

export default {
  name: 'TableHours',
 
  mixins: [Roles],

  props: ['classSchedule'],

  data: () => ({
    optionsDaysWeek: Object.values(DaysWeek),
    schedule: new ClassSchedule(),
    timeBegin: '',
    timeEnd: '',
    dateBegin: '',
    dateEnd: '',
    emptyTextClass: 'Não há horários cadastrados',
    selectedDayWeek: null,
    showModalDeleteClass: false,
    showModalClassHours: false,
    fieldClass: [
      {
        key: 'dayWeek',
        label: 'DIA DA SEMANA',
        class: 'left-header-border text-center',
        formatter: 'getSelectDaysWeek'
      },
      {
        key: 'timeStart',
        label: 'HORÁRIO DE INÍCIO',
        class: 'middle-header text-center',
        formatter: 'formatTime'
      },
      {
        key: 'timeEnd',
        label: 'HORÁRIO FINAL',
        class: 'middle-header text-center',
        formatter: 'formatTime'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ]
  }),

  methods: {
    onRowSelectedDelete(id) {
      this.id = id
      this.showModalDeleteClass = true
    },

    formatTime(time) {
      return time.substring(0, 5)
    },

    onDelete() {
      loading.push()
      ClassScheduleRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.showModalDeleteClass = false
          toast.success('Grade de horário excluída com sucesso!', 'EXCLUSÃO DE HORÁRIOS')
          this.getById(this.$route.params.id)

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir uma grade de horário', 'ERRO')
        })
    },

    onSave() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.mergeItemList()
          this.clearForm()
          this.showModalClassHours = false
        } else {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
        }
      })
    },

    clearForm() {
      this.schedule = new ClassSchedule()
    },

    checkDaysDisable (day) {
      return this.classSchedule.some(x => x.dayWeek == day)
    },

    mergeItemList() {
      const days = this.schedule.dayWeek

      days.forEach(day => {
        const schedule = {
          dayWeek: day,
          timeStart: this.schedule.timeStart,
          timeEnd: this.schedule.timeEnd
        }

        this.classSchedule.push(schedule)
      })
    },

    removeItemList() {
      this.classSchedule.splice(this.scheduleIndexSelected, 1)
      this.showModalDeleteClass = false
    },

    getSelectDaysWeek(dayWeek) {
      const dayWeekObj = this.optionsDaysWeek.find(day => day.value == dayWeek)

      if (!dayWeekObj)
        return

      return dayWeekObj.text
    }
  }
}
