const FrequencyStatus = {
  PENDING: { id: 0, name: 'Pendente' },
  REALIZED: { id: 1, name: 'Realizada' },
  // WAITING_SINCHRONIZATION: { id: 2, name: 'Aguardando Sincronização' }
  CANCELED: { id: 3, name: 'Cancelada' }
}

Object.freeze(FrequencyStatus)

export default FrequencyStatus
