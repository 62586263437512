import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalitylocaltraining'
import ClassRegistrationRepository from '@/shared/http/repositories/socialProject/classregistration'
import CategoryRepository from '@/shared/http/repositories/socialProject/category'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import Roles from '@/shared/mixins/hasRoles'

export default {
  name: 'StudentMoviment',

  mixins: [Roles],

  props: {
    movRegisteredStudents: {
      type: Array
    },
    movRegistrationsStudents: {
      type: Array
    }
  },

  data: () => ({
    movimentLocalTrainingId: null,
    movimentModalityId: null,
    movimentCategoryId: null,
    movimentClasseId: null,
    modalityLocalTraining: [],
    listLocalTraining: [],
    categories: [],
    modalities: [],
    movimentClasses: []
  }),

  created() {
    this.loadModalityLocalTraining()
  },

  methods: {
    loadModalityLocalTraining() {
      this.modalityLocalTraining = []
      loading.push()
      ModalityLocalTrainingRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.modalityLocalTraining = res.data.data
          this.onSelectModality()
          this.onSelectLocalTraining()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar a lista de Modalidades e Locais de treinamento.', 'ERRO')
        })
    },

    onSave() {
      ClassRegistrationRepository.SaveMovement(this.movimentClasseId, this.movRegisteredStudents)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if (this.movRegisteredStudents) {
            this.movRegisteredStudents.forEach(element => {
              var index = this.movRegistrationsStudents.findIndex(item => item.id === element.id)
              this.movRegistrationsStudents.splice(index, 1)
            })
          }
          this.clearform()
          this.$emit('loadClassRegistration')

          window.scrollTo(0, 0)
          loading.pop()
          toast.success('Movimentação realizada com sucesso!', 'SUCESSO')
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível realizar a movimentação.', 'ERRO')
        })
    },

    onSelectLocalTraining() {
      let list = this.modalityLocalTraining

      if (this.movimentLocalTrainingId) {
        const type = this.listLocalTraining.filter(s => s.id === this.movimentLocalTrainingId)

        if (type.length > 0) {
          this.localTrainingName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.localTrainingId === type[0].id)
        }
      }

      this.modalities = []
      for (var i = 0; i < list.length; i++) {
        let modality = {
          id: list[i].modalityId,
          name: list[i].modalityName
        }

        if (this.modalities.filter(m => m.id === modality.id).length === 0) {
          this.modalities.push(modality)
        }
      }
    },

    onSelectModality() {
      let list = this.modalityLocalTraining

      if (this.movimentModalityId) {
        let type = this.modalities.filter(s => s.id === this.movimentModalityId)

        if (type.length > 0) {
          this.modalityName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.modalityId === type[0].id)
        }
      }

      this.listLocalTraining = []
      for (var i = 0; i < list.length; i++) {
        const local = {
          id: list[i].localTrainingId,
          name: list[i].localTrainingName
        }

        if (this.listLocalTraining.filter(l => l.id === local.id).length === 0) {
          this.listLocalTraining.push(local)
        }
      }

      this.categories = []
      if (this.movimentModalityId) {
        CategoryRepository.GetAllByModality(this.movimentModalityId)
          .then(response => {
            this.categories = response.data.data
          })
          .catch(() => {
            this.$refs.snackBar.ShowError('Não foi possível carregar a lista de Categorias.')
          })
      }
    },

    onSelectCategory() {
      if (this.movimentCategoryId) {
        ClassRepository.GetAllByCategory(this.movimentCategoryId)
          .then(response => {
            this.movimentClasses = response.data.data
          })
          .catch(() => {
            this.$refs.snackBar.ShowError('Não foi possível carregar a lista de Turmas.')
          })
      }
    },

    clearform() {
      this.movimentLocalTrainingId = null,
        this.movimentModalityId = null,
        this.movimentCategoryId = null,
        this.movimentClasseId = null
    },
  },
}
